<template>
  <div class="opportunityManage">
    <component :is="'List'" ref="opportunityManage" :leadStageCode="'OPPORTUNITY'"/>
  </div>
</template>
<script>
import List from '../clue/list'
export default {
  name: 'OpportunityManage',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'OpportunityManage')
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.$store.commit('app/deleteKeepAlive', 'OpportunityManage')
    }
    next()
  },
  components: {
    List,
  },
  data() {
    return {
    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang="less" scoped>
.opportunityManage{}
</style>
